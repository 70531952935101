<template>
    <div class="app-container">
        <el-button type="primary" @click="handleCreateRole">
            {{ $t("permission.createRole") }}
        </el-button>

        <el-table :data="rolesList" v-loading="isLoading" style="width: 100%;margin-top:30px;" border>
            <el-table-column align="center" label="Account" width="220">
                <template #default="{ row }">
                    {{ row.Account }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="Roles" width="350">
                <template #default="{ row }">
                    <el-tag v-for="item of GetRoleDes(row.Roles)" :key="item" style="margin-right:2px;">
                        {{ item }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="header-center" label="Description">
                <template #default="{ row }">
                    {{ row.Description }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="LastLoginTime" width="220">
                <template #default="{ row }">
                    {{ new Date(Number(row.LastLoginTime)).toLocaleString() }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="Operations">
                <template #default="{ row }">
                    <el-button v-if="row.GmLevel < 2048" type="primary" size="small" @click="handleEdit(row)">
                        {{ $t("permission.editPermission") }}
                    </el-button>
                    <el-button v-if="row.GmLevel < 2048" type="success" size="small" @click="handleResertPassword(row)">
                        {{ $t("permission.lookPassword") }}
                    </el-button>
                    <el-button v-if="row.GmLevel < 2048" type="danger" size="small" @click="handleDelete(row)">
                        {{ $t("permission.delete") }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog v-model="dialogCreateVisible" :title="'New Account'">
            <el-form :model="role" label-width="80px" label-position="left">
                <el-form-item label="Account">
                    <el-input v-model="role.Account" placeholder="Account" />
                </el-form-item>
                <el-form-item label="Password">
                    123456abc
                </el-form-item>
                <el-form-item label="GMRole">
                    <draggable-select :value="createGmRole" style="width:500px;" multiple placeholder="Please select"
                        @changeVal="changeGmRole">
                        <el-option v-for="item in RolesTreeData()" :key="item.title" :label="item.title"
                            :value="item.path" />
                    </draggable-select>
                </el-form-item>
                <el-form-item label="Desc">
                    <el-input v-model="role.Description" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                        placeholder="Role Description" />
                </el-form-item>
            </el-form>
            <div style="text-align:right;">
                <el-button type="danger" @click="dialogCreateVisible = false">
                    {{ $t("permission.cancel") }}
                </el-button>
                <el-button type="primary" @click="confirmCreateRole">
                    {{ $t("permission.confirm") }}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog v-model="dialogEditVisible" :title="'Edit Account'">
            <el-form :model="role" label-width="80px" label-position="left">
                <el-form-item label="Account">
                    {{ role.Account }}
                </el-form-item>
                <el-form-item label="GMRole">
                    <draggable-select :value="role.Roles" style="width:500px;" multiple placeholder="Please select"
                        @changeVal="updateGmRole">
                        <el-option v-for="item in RolesTreeData()" :key="item.title" :label="item.title"
                            :value="item.path" />
                    </draggable-select>
                </el-form-item>
                <el-form-item label="Desc">
                    <el-input v-model="role.Description" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                        placeholder="Role Description" />
                </el-form-item>
            </el-form>
            <div style="text-align:right;">
                <el-button type="danger" @click="dialogEditVisible = false">
                    {{ $t("permission.cancel") }}
                </el-button>
                <el-button type="primary" @click="confirmUpdateRole">
                    {{ $t("permission.confirm") }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script lang="ts">
import { User_EditAccount, User_GMGetAllUserInfo, User_GMRegiste } from "@/apis/user";
import draggableSelect from '@/components/draggable-select/Index.vue';
import { ERoleType } from "@/constant/network";
import { ElMessage, ElMessageBox } from "element-plus";
import { cloneDeep } from "lodash";
import { defineComponent, nextTick, onMounted, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteRecordRaw } from 'vue-router';
const defaultRole: Partial<IRole> = {
    Id: "",
    GmLevel: 0,
    Account: "",
    Description: "",
    LastLoginTime: "",
    Roles: [],
};

export default defineComponent({
    components: {
        draggableSelect
    },
    setup() {
        const { t } = useI18n()
        const dataMap = reactive(new ViewData())
        for (let k in ERoleType) {
            const v = ERoleType[k];
            if (typeof v == "number") {
                dataMap.roleEnumInfo.push({ label: k, enum: v });
            }
        }
        async function getAccounts() {
            dataMap.isLoading = true;
            dataMap.rolesList.length = 0;

            let cbmsg = await User_GMGetAllUserInfo();
            if (cbmsg!.Error !== 0) {
                ElMessage.error("no data +\n" + cbmsg!.Message);
                return;
            }
            const cbdata = JSON.parse(cbmsg!.Message) as IRole[];
            cbdata.forEach(a => {
                console.log(a, 11)
                a.Account = a.Account || "";
                a.Description = a.Description || "";
                a.LastLoginTime = a.LastLoginTime || "";
                a.GmLevel = a.GmLevel || 0;
                a.Roles = a.Roles || [];
                dataMap.rolesList.push(a);
            });
            dataMap.isLoading = false;
        }

        function handleCreateRole() {
            dataMap.role = Object.assign({}, defaultRole) as IRole;
            dataMap.dialogCreateVisible = true;
        }

        async function confirmCreateRole() {
            let message = "";
            if (dataMap.role.Account.length < 6 || dataMap.role.Account.length > 20) {
                message.length == 0 && (message = "account length error");
            }
            if (dataMap.role.Account.indexOf(" ") > -1) {
                message.length == 0 && (message = "account has empty");
            }
            if (dataMap.role.Description.length > 100) {
                message.length == 0 && (message = "description length error");
            }
            const checkedRoleKeys = dataMap.createGmRole;
            if (checkedRoleKeys.length == 0) {
                message.length == 0 && (message = "select GmRole error");
            }
            if (message.length > 0) {
                ElMessage.error(message);
                return;
            }
            dataMap.dialogCreateVisible = false;
            let msg = {} as IC2G_GMRegiste;
            msg.Account = dataMap.role.Account;
            msg.Password = "123456abc";
            msg.GmLevel = 0;
            for (let k of checkedRoleKeys) {
                msg.GmLevel += k;
            }
            msg.Des = dataMap.role.Description;
            msg.Roles = checkedRoleKeys;
            const CBmsg = await User_GMRegiste(msg);
            dataMap.dialogCreateVisible = false;
            if (CBmsg!.Error !== 0) {
                ElMessage.error(CBmsg?.Message!)

            } else {
                ElMessage.success(`create account<${dataMap.role.Account}> success`)
                await getAccounts();
            }
        }

        function handleEdit(row: IRole) {
            dataMap.dialogEditVisible = true;
            dataMap.checkStrictly = true;
            dataMap.role = cloneDeep(row);
            nextTick(() => {
                // set checked state of a node not affects its father and child nodes
                dataMap.checkStrictly = false;
            });
        }
        async function confirmUpdateRole() {
            let message = "";
            if (dataMap.role.Description.length > 100) {
                message.length == 0 && (message = "description length error");
            }
            const checkedRoleKeys = dataMap.role.Roles;
            if (checkedRoleKeys.length == 0) {
                message.length == 0 && (message = "select GmRole error");
            }
            if (message.length > 0) {
                ElMessage.error(message);
                return;
            }
            dataMap.dialogEditVisible = false;
            let msg = {} as IC2G_GMEditAccount;
            msg.Account = dataMap.role.Account;
            msg.GmLevel = 0;
            for (let k of checkedRoleKeys) {
                msg.GmLevel += k;
            }
            msg.Des = dataMap.role.Description;
            msg.Roles = checkedRoleKeys;
            msg.Operate = 0;
            const CBmsg = await User_EditAccount(msg);
            dataMap.dialogEditVisible = false;
            if (CBmsg!.Error !== 0) {
                ElMessage.error(CBmsg?.Message!)
            } else {
                ElMessage.success(`update account<${dataMap.role.Account}> success`)
                await getAccounts();
            }
        }

        function handleResertPassword(row: IRole) {
            ElMessageBox.confirm("Confirm to resertPassword the role?", "Warning", {
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
                type: "warning"
            }).then(async () => {
                let msg = {} as IC2G_GMEditAccount;
                msg.Account = row.Account;
                msg.Operate = 1;
                let CBmsg = await User_EditAccount(msg);
                if (CBmsg!.Error !== 0) {
                    ElMessage.error(CBmsg?.Message!)
                } else {
                    ElMessage.success(`ResertPassword account<${row.Account}> success
                      <br>new password => 123456abc`)
                    await getAccounts();
                }
            }).catch(err => {
                console.error(err);
            });
        }

        function handleDelete(row: IRole) {
            ElMessageBox.confirm("Confirm to remove the role?", "Warning", {
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
                type: "warning"
            }).then(async () => {
                let msg = {} as IC2G_GMEditAccount;
                msg.Account = row.Account;
                msg.Operate = 2;
                let CBmsg = await User_EditAccount(msg);
                if (CBmsg!.Error !== 0) {
                    ElMessage.error(CBmsg?.Message!)
                } else {
                    ElMessage.success(`Delete account<${row.Account}> success`)
                    await getAccounts();
                }
            })
                .catch(err => {
                    console.error(err);
                });
        }

        const RolesTreeData = () => {
            const data: IRolesTreeData[] = [];
            for (const route of dataMap.roleEnumInfo) {
                // const v = ERoleType[route];
                if (route.enum < 2048) {
                    const tmp: IRolesTreeData = {
                        title: "",
                        path: 0
                    };
                    tmp.title = `${route.label}`;
                    tmp.path = route.enum;
                    data.push(tmp);
                }
            }
            return data;
        }
        function GetRoleDes(val: number[]) {
            return val.map(v => {
                return ERoleType[v] as string
            })
        }
        function changeGmRole(val: number[]) {
            dataMap.createGmRole = val
        }
        function updateGmRole(val: number[]) {
            dataMap.role.Roles = val
        }


        onMounted(() => {
            getAccounts()
        })
        return {
            t, ...toRefs(dataMap),
            handleCreateRole,
            confirmCreateRole,
            handleEdit,
            confirmUpdateRole,
            handleResertPassword,
            handleDelete,
            RolesTreeData,
            changeGmRole,
            GetRoleDes,
            updateGmRole,
        }
    }
})



class ViewData {
    isLoading = false;
    reshapedRoutes: RouteRecordRaw[] = [];
    serviceRoutes: RouteRecordRaw[] = [];
    rolesList: IRole[] = [];
    roleEnumInfo: { label: string; enum: number }[] = [];
    createGmRole: number[] = [];
    role: IRole = Object.assign({}, defaultRole) as IRole;
    dialogCreateVisible = false;
    dialogEditVisible = false;
    checkStrictly = false;
    defaultProps = {
        children: "children",
        label: "title"
    };

}
</script>
  
<style lang="scss" scoped>
.app-container {
    .roles-table {
        margin-top: 30px;
    }

    .permission-tree {
        margin-bottom: 30px;
    }
}
</style>
  